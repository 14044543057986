import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import React from "react";

const CustomLoadingButton = ({ children, loading, sx, ...rest }) => {
    return (
        <LoadingButton
            loadingIndicator={<CircularProgress size={20} />}
            loading={loading}
            variant="contained"
            sx={{
                "&.Mui-disabled": {
                    color: "#8E97A4",
                    boxShadow: "none",
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                    cursor: "not-allowed",
                    pointerEvents: "auto",
                },
                "&.MuiLoadingButton-loading": {
                    color: "transparent",
                },
                ...sx,
            }}
            {...rest}
        >
            {children}
        </LoadingButton>
    );
};

export default CustomLoadingButton;
