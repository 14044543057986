import { useCallback, useMemo } from "react";
import Joyride, { EVENTS, STATUS } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserTourSteps,
  setUserTourSteps,
} from "../redux/Slice/Auth/authSlice";
import { useLocation } from "react-router-dom";

export default function useTour(steps) {
  const dispatch = useDispatch();

  const location = useLocation();

  const { run } = useSelector(getUserTourSteps);
  const userTourSteps = useSelector(getUserTourSteps);

  const handleJoyrideCallback = useCallback(
    function (data) {
      const { status, type } = data;
      console.log({ status, type, STATUS, data });
      const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
      if ([EVENTS.BEACON].includes(type)) {
        dispatch(setUserTourSteps({ ...userTourSteps, run: false }));
      }
      if (finishedStatuses.includes(status)) {
        dispatch(setUserTourSteps({ ...userTourSteps, run: false }));
      }
    },
    [dispatch, userTourSteps]
  );

  const tour = useMemo(() => {
    if (location.pathname !== "/intel-flow") return null;

    return (
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        disableScrollParentFix
        steps={steps}
        locale={{ last: "Done", back: "Previous" }}
        styles={{
          options: {
            zIndex: 10000,
            backgroundColor: "rgba(17, 32, 56, 1)",
            arrowColor: "rgba(17, 32, 56, 1)",
            textColor: "#fff",
            spotlightShadow: "#000",
            width: "40rem",
          },
        }}
      />
    );
  }, [location.pathname, steps, handleJoyrideCallback, run]);

  return tour;
}
