import { Backdrop } from "@mui/material";
import "./loader.css";

const BackdropLoader = ({ loading }) => {
  return (
    <>
      <Backdrop
        sx={{ color: "#0083f9", zIndex: (theme) => theme.zIndex.drawer + 9999 }}
        open={loading}
      >
        <div className="loader" />
      </Backdrop>
    </>
  );
};

export default BackdropLoader;
