import { styled, Tooltip } from "@mui/material";
import React from "react";

const CustomStyle = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
        backgroundColor: "#051123",
        color: "#ffffff",
        padding: "10px",
        borderRadius: "4px",
        fontWeight: "bold",
    },
    [`& .MuiTooltip-arrow`]: {
        color: "#051123",
    },
}));

const CustomTooltip = ({ title, children, ...rest }) => {
    return (
        <>
            {typeof children === "string" ? (
                <CustomStyle title={title} arrow {...rest}>
                    <span>{children}</span>
                </CustomStyle>
            ) : (
                <CustomStyle title={title} arrow {...rest}>
                    {children}
                </CustomStyle>
            )}
        </>
    );
};

export default CustomTooltip;
