import React, { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import BackdropLoader from "../Components/Loader/BackdropLoader";
import Layout from "../layout";
import ComingSoon from "../layout/ComingSoon";
// import Appbar from "../Components/Appbar/Appbar";
// import MainScreen from "../Screens/ThreatActors/MainScreen/MainScreen";
// import SpinnerLoader from "../Components/Loader/SpinnerLoader";
// import SingleEntityScreen from "../Screens/TID/EntitiesListsScreen/SingleEntityScreen/SingleEntityScreen";
// import DataCreation from "../Screens/TID/DataCreation/DataCreationScreen";
// import CreateThreatScreen from "../Screens/TID/DataCreation/CreateNewThreat/CreateThreatScreen";
// import CreateRuleScreen from "../Screens/TID/Detection/CreateRule/CreateRuleScreen";
// import Investigation from "../Investigation/Components/Investigation";
// import CreateRisk from "../RiskMangement/CreateRisk/createRisk";
// import RiskManagement from "../RiskMangement/riskManagement";
// import SignUp from "../modules/auth/signUp";
import PrivateRoute from "../modules/auth/privateRoute/PrivateRoute";
// import RemediationsDetail from "../modules/TID/intelFlow/Remediations/RemediationsDetail";
// import SelectThreat from "../modules/TID/intelFlow/Create";
// import CreateThreat from "../modules/TID/intelFlow/Create/Create-Threat-Screen";
// import SignIn from "../modules/auth/signIn";
// import HeaderIntelFlow from "../modules/TID/intelFlow/HeaderIntelFlow";
// const TIDBreadCrumb = lazy(() => import("../TID/Components/TIDBreadCrumb/TIDBreadCrumb"));
// const EntitiesListScreen = lazy(() => import("../Screens/TID/EntitiesListsScreen/EntitiesListScreen"));
// const ATTCKComparisonScreen = lazy(() => import("../Screens/TID/ATT&CKComparison/ATTCKComparisonScreen"));
// const TIDTabs = lazy(() => import("../TID/Components/TIDTabs/TIDTabs"));
// const DetectionScreen = lazy(() => import("../Screens/TID/Detection/DetectionScreen"));
// const RiskScore = lazy(() => import("../Screens/TID/RiskScore/RiskScoreScreen"));
// const DownloadReport = lazy(() => import("../TID/Components/TIDDownloadReport/DownloadReport"));
// const TIDTemplate = lazy(() => import("../TID/Components/TIDTemplates/TIDTemplate"));
const SignUp = lazy(() => import("../modules/auth/signUp"));
const SignIn = lazy(() => import("../modules/auth/signIn"));
const IntelFlowDashboard = lazy(() => import("../modules/TID/dashboard"));
const HeaderIntelFlow = lazy(() =>
  import("../modules/TID/intelFlow/HeaderIntelFlow")
);
const Threats = lazy(() => import("../modules/TID/intelFlow/Threats"));
const Indicators = lazy(() => import("../modules/indicators"));
const CreateIndicator = lazy(() =>
  import("../modules/indicators/create-indicator")
);
const SelectThreat = lazy(() => import("../modules/TID/intelFlow/Create"));
const CreateThreat = lazy(() =>
  import("../modules/TID/intelFlow/Create/Create-Threat-Screen")
);
const EntityScreenDetailed = lazy(() =>
  import("../modules/TID/intelFlow/Threats/ThreatDetailView")
);
const MitreAttack = lazy(() =>
  import("../modules/TID/intelFlow/Attacks-Matrix/MitreAttack")
);
const Remediations = lazy(() =>
  import("../modules/TID/intelFlow/Remediations")
);
const RemediationsDetail = lazy(() =>
  import("../modules/TID/intelFlow/Remediations/RemediationsDetail")
);
const RemediationsCompare = lazy(() =>
  import("../modules/TID/intelFlow/Remediations/RemediationCompare")
);
const HuntAndDetect = lazy(() =>
  import("../modules/TID/intelFlow/Hunt&Detect")
);
const DetectionDetails = lazy(() =>
  import("../modules/TID/intelFlow/Hunt&Detect/DetectionDetails")
);
const Recommendation = lazy(() =>
  import("../modules/TID/intelFlow/Recommendation")
);
const RiskMatrixScreen = lazy(() =>
  import("../modules/TID/intelFlow/Risk-Matrix")
);
const MyTaskScreen = lazy(() => import("../modules/MyTasks"));
const Setting = lazy(() => import("../modules/setting"));
const ShowResults = lazy(() =>
  import("../modules/TID/intelFlow/SerachModule/SearchedResults")
);
const MyWorkspace = lazy(() => import("../modules/myWorkspace"));
const WorkspaceLists = lazy(() =>
  import("../modules/myWorkspace/WorkspaceLists")
);
const ThreatProfile = lazy(() => import("../modules/threat-profile"));
const ThreatProfileList = lazy(() => import("../modules/threat-profile/list"));
const BaselineControlsMain = lazy(() =>
  import("../modules/threat-profile/baseline-controls")
);
const SecurityFramework = lazy(() =>
  import("../modules/threat-profile/baseline-controls/security-framework")
);
const AssocitedProdMain = lazy(() =>
  import("../modules/threat-profile/baseline-controls/associated-products")
);

const router = createBrowserRouter([
  // {
  //     element:<PrivateRoute/>,
  //     children:[]
  // },
  // {
  //     path: "/",
  //     element: <PrivateRoute Component={Appbar} />,
  //     // errorElement: <NotFound />,
  //     children: [
  //         { path: "/", element: <MainScreen /> },
  //         // { path: "/newactor", element: <CreateActor /> },
  //         // { path: "/newactor/:id", element: <CreateActor /> },
  //         // {
  //         //     path: "/singlecard/:actorId",
  //         //     element: <SingleCard />,
  //         //     children: [
  //         //         // {
  //         //         //   path: "",
  //         //         //   // loader: <SpinnerLoader />,
  //         //         //   element: (
  //         //         //     <React.Suspense fallback={<SpinnerLoader />}>
  //         //         //       <Grid />
  //         //         //     </React.Suspense>
  //         //         //   ),
  //         //         // },
  //         //         {
  //         //             path: "", // This will match /SingleCard/:actorId by default
  //         //             element: (
  //         //                 <React.Suspense fallback={<SpinnerLoader />}>
  //         //                     <Activity />
  //         //                 </React.Suspense>
  //         //             ),
  //         //             children: [
  //         //                 {
  //         //                     path: "",
  //         //                     element: (
  //         //                         <React.Suspense fallback={<SpinnerLoader />}>
  //         //                             <ActivityTables />
  //         //                         </React.Suspense>
  //         //                     ),
  //         //                 },
  //         //                 {
  //         //                     path: "channal/:channelId",
  //         //                     element: <ActivityThreads />,
  //         //                     children: [
  //         //                         {
  //         //                             path: "",
  //         //                             element: (
  //         //                                 <React.Suspense fallback={<SpinnerLoader />}>
  //         //                                     <ActivityChannal />
  //         //                                 </React.Suspense>
  //         //                             ),
  //         //                         },
  //         //                         { path: "message", element: <SocialRead /> },
  //         //                     ],
  //         //                 },
  //         //             ],
  //         //         },
  //         //         {
  //         //             path: "mitre",
  //         //             // loader: <SpinnerLoader />,
  //         //             element: (
  //         //                 <React.Suspense fallback={<SpinnerLoader />}>
  //         //                     <TabMitreTable />
  //         //                 </React.Suspense>
  //         //             ),
  //         //         },
  //         //         {
  //         //             path: "report",
  //         //             element: (
  //         //                 <React.Suspense fallback={<SpinnerLoader />}>
  //         //                     <ReportTable />
  //         //                 </React.Suspense>
  //         //             ),
  //         //         },
  //         //     ],
  //         // },
  //         // { path: "/compare", element: <MitreAttackScreen /> },
  //         // { path: "/courseofaction", element: <Course /> },
  //         // { path: "/finish", element: <Finish /> },
  //         // {
  //         //     path: "/tid",
  //         //     element: (
  //         //         <Suspense fallback={<SpinnerLoader />}>
  //         //             <TIDBreadCrumb />
  //         //         </Suspense>
  //         //     ),
  //         //     children: [
  //         //         { path: "", element: <EntitiesListScreen />, exact: true },
  //         //         { path: "attack", element: <ATTCKComparisonScreen /> },
  //         //         { path: "threat-coverage", element: <TIDTabs /> },
  //         //         { path: "detection", element: <DetectionScreen /> },
  //         //         { path: "risk-score", element: <RiskScore /> },
  //         //         { path: "download", element: <DownloadReport /> },
  //         //         { path: "download/template", element: <TIDTemplate /> },
  //         //         // { path: "download", element: <Finish /> },
  //         //     ],
  //         // },
  //         // {
  //         //     path: "/tid/:id",
  //         //     element: <SingleEntityScreen />,
  //         // },
  //         // {
  //         //     path: "/tid/select-threat",
  //         //     element: <DataCreation />,
  //         //     // children: [{ path: "create-threat", element: <EntitiesListScreen /> }],
  //         // },
  //         // {
  //         //     path: "/tid/select-threat/create-threat",
  //         //     element: <CreateThreatScreen />,
  //         // },
  //         // {
  //         //     path: "/tid/select-threat/update-threat/:id",
  //         //     element: <CreateThreatScreen />,
  //         // },
  //         // {
  //         //     path: "/tid/create-rule",
  //         //     element: <CreateRuleScreen />,
  //         // },
  //         // {
  //         //     path: "/investigation",
  //         //     element: <Investigation />,
  //         // },
  //         // {
  //         //     path: "/risk-management",
  //         //     element: <RiskManagement />,
  //         // },
  //         // // {
  //         // //     path: "/create-risk",
  //         // //     element: <CreateRisk />,
  //         // // },
  //         // {
  //         //     path: "/create-risk/:id",
  //         //     element: <CreateRisk />,
  //         // },
  //     ],
  // },
  {
    path: "/login",
    element: (
      <Suspense fallback={<BackdropLoader loading />}>
        <SignIn />
      </Suspense>
    ),
  },
  {
    path: "/signup",
    element: (
      <Suspense fallback={<BackdropLoader loading />}>
        <SignUp />
      </Suspense>
    ),
  },
  {
    path: "/",
    element: <PrivateRoute Component={Layout} />,
    children: [
      {
        path: "dashboard",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <IntelFlowDashboard />
          </Suspense>
        ),
      },
      {
        path: "intel-flow",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <HeaderIntelFlow />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: <Threats />,
          },
          {
            path: "graphviews",
            element: <MitreAttack />,
          },
          {
            path: "remediations",
            element: <Remediations />,
          },
          {
            path: "remediations/:id",
            element: <RemediationsDetail />,
          },
          {
            path: "remediations/compare/:controlId",
            element: <RemediationsCompare />,
          },
          {
            path: "hunt-detect",
            element: <HuntAndDetect />,
          },
          {
            path: "hunt-detect/:id",
            element: <DetectionDetails />,
          },
          {
            path: "recommendation",
            element: <Recommendation />,
          },
          {
            path: "risk-matrix",
            element: <RiskMatrixScreen />,
          },
        ],
      },
      {
        path: "entity/:id",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <EntityScreenDetailed />
          </Suspense>
        ),
      },
      {
        path: "select-threat",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <SelectThreat />
          </Suspense>
        ),
      },
      {
        path: "create-threat",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <CreateThreat />
          </Suspense>
        ),
      },
      {
        path: "create-threat/:id",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <CreateThreat />
          </Suspense>
        ),
      },
      {
        path: "indicators",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <Indicators />
          </Suspense>
        ),
      },
      {
        path: "indicators/create",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <CreateIndicator />
          </Suspense>
        ),
      },
      {
        path: "indicators/edit/:id",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <CreateIndicator type="edit" />
          </Suspense>
        ),
      },
      {
        path: "indicators/create-bulk",
        element: <CreateIndicator type="bulk-create" />,
      },
      {
        path: "my-tasks",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <MyTaskScreen />
          </Suspense>
        ),
      },
      {
        path: "my-workspace",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <MyWorkspace />
          </Suspense>
        ),
      },
      {
        path: "my-workspace/:id",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <WorkspaceLists />
          </Suspense>
        ),
      },
      {
        path: "threat-profile",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <ThreatProfileList />
          </Suspense>
        ),
      },
      {
        path: "/threat-profile/onboard",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <ThreatProfile />
          </Suspense>
        ),
      },
      {
        path: "/threat-profile/:threatProfileId",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <BaselineControlsMain />
          </Suspense>
        ),
      },
      {
        path: "/threat-profile/:threatProfileId/security-framework",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <SecurityFramework />
          </Suspense>
        ),
      },
      {
        path: "/threat-profile/:threatProfileId/associated-products",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <AssocitedProdMain />
          </Suspense>
        ),
      },
      {
        path: "settings",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <Setting />
          </Suspense>
        ),
      },
      { path: "risk-management", element: <ComingSoon /> },
      { path: "investication", element: <ComingSoon /> },

      { path: "support", element: <ComingSoon /> },

      {
        path: "search-results",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <ShowResults />
          </Suspense>
        ),
      },
    ],
  },
]);

export default router;
